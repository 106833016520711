// src/services/apiService.js
import axios from 'axios';

// Konfigurasi dasar untuk axios
const token = 'f0a30db628d82442617d7cff06501e98';
const bearerToken = 'Bearer ' + token;

const api = axios.create({
  baseURL: 'https://zertall.gravix.my.id/api', // Ganti dengan URL API Anda
  timeout: 10000, // Waktu tunggu maksimum
  headers: {
    'Content-Type': 'application/json',
    'Authorization': bearerToken
  },
});

// Fungsi untuk mendapatkan data
export const fetchData = async (endpoint) => {
  try {
    const response = await api.get(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// Fungsi untuk mengirim data
export const postData = async (endpoint, data) => {
  try {
    const response = await api.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
