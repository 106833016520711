// src/pages/team/Team.js
import React, { useEffect, useState } from 'react';
import { fetchData } from '../../services/apiService';

const Team = () => {
  const [teamData, setTeamData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getTeamData = async () => {
      try {
        const result = await fetchData('/team'); 
        console.log(result); 

        // Akses data anggota tim dari properti 'data'
        if (result.status === 'success' && Array.isArray(result.data)) {
          setTeamData(result.data);
        } else {
          throw new Error('Data tidak ditemukan.');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getTeamData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h2>Team Members</h2>
      <ul>
        {teamData.length > 0 ? (
          teamData.map((member) => (
            <li key={member.id}>
              <strong>Name:</strong> {member.name} <br />
              <strong>Email:</strong> {member.email} <br />
              <strong>Phone:</strong> {member.phone || 'N/A'} <br />
              <strong>Position:</strong> {member.position} <br />
            </li> // Anda bisa menyesuaikan informasi yang ingin ditampilkan
          ))
        ) : (
          <li>No team members found.</li>
        )}
      </ul>
    </div>
  );
};

export default Team;
