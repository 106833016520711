import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import Footer from './components/Footer';
import Dashboard from './pages/dashboard/Dashboard';
import Team from './pages/team/Team';

import './assets/css/bootstrap.min.css';
import './assets/css/icons.min.css';
import './assets/css/app.min.css';
import './assets/css/styles.css'; 

const App = () => {
  return (
    <Router>
      <div id="layout-wrapper">
        {/* Topbar */}
        <Topbar />

        {/* Layout */}
        <div className="vertical-menu">
          {/* Sidebar */}
          <Sidebar />
        </div>

        {/* Content */}
        <div className="main-content">
          <div className="page-content">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/team" element={<Team />} />
              {/* Redirect to Dashboard if route not found */}
              <Route path="*" element={<Dashboard />} />
            </Routes>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </div>
    </Router>
  );
};


export default App;
