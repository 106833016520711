// src/components/Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div className="sidebar">
      <h2>Menu</h2>
      <ul>
        <li>
          <Link to="/dashboard">Dashboard</Link>
        </li>
        <li>
          <Link to="/team">Team</Link>
        </li>
        {/* Tambahkan item menu lainnya sesuai kebutuhan */}
      </ul>
    </div>
  );
};

export default Sidebar;
