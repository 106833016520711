// src/components/Topbar.js
import React from 'react';

const Topbar = () => {
  return (
    <div className="topbar">
      <h1>Your Application</h1>
      {/* Tambahkan elemen lain seperti ikon atau dropdown jika diperlukan */}
    </div>
  );
};

export default Topbar;
